// @flow

import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types'

const overview = [
  {
    title: 'Colorful & Elegant',
    message:
      'Stunning Island Cottage has a colorful interior and designer extras.',
  },
  {
    title: 'Steps Away from The Gulf',
    message:
      'The beautiful Island cottage is just steps away from the gulf of Mexico.',
  },
  {
    title: 'Room Configuration',
    message: '2 Bedroom (1Q, 2T) / 2 Bath with a sleeper sofa in living room.',
  },
]

export const islandOverview: OverviewItem[] = map(generateIdFromTitle, overview)

export default islandOverview
